import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CouponField from '../CouponField';
import { useHttp } from 'hooks/httpHook';
//MUI 
import Popper from '@material-ui/core/Popper';
import CartComment from '../../Cart/CartComment';
import axios from 'axios';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
//ICONS 
import Close from '@material-ui/icons/Close';
import Message from '@material-ui/icons/Message';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
//COLORS
import { pink, grey } from '@material-ui/core/colors';

const styles = theme => ({
  ...theme.cart,
  total: {
    width: '100%',
    height: '100%',
    background: 'white',
    position: 'relative',
    padding: '15px',
  },
  orderInfo: {
    position: 'relative',
    width: '100%',
    border: '1px solid #C3C3C3',
    borderRadius: '10px',
    padding: '10px'
  },
  returnButton: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  calculator: {
    flexBasis: '50%',
    background: '#353535'
  },
  title: {
    textAlign: 'center',
  },
  tableWrap: {
    height: '340px',
    overflow: 'auto',
    marginTop: '1rem',
  },
  totalPrice: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    width: '100%'
  },
  totalTypography: {
    display: 'block'
  },
  shippingOrderButton: {
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  popperHint: {
    margin: '5px'
  },
  actionButtons: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionButton: {
    minWidth: '130px',
    color: 'white',
    borderRadius: '10px',
    textTransform: 'none',
    fontSize: '1.2rem',
    '&:nth-child(1)': {
      background: '#B1B1B1'
    }
  },
  orderInfoFooter: {
    width: '100%',
    position: 'absolute',
    padding: '15px',
    bottom: 0,
    left: 0
  },
  payButtons: {
    width: '450px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  spinner:{
    color: '#fff',
  },
})
const OrderInfo = ({orderPending, reduxFunctions: { changeComment, makeReturnToCart }, handleOpenCalculator, fakePayment, classes, state: { data, cart, promo }, rows, cardPayment }) => {
  const [openPay, setOpenPay] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentItem, setCommentItem] = useState({});
  


  const { orderId, checkId } = data;
  const history = useHistory();
  const moveToDeliveryPage = async () => {
    await makeReturnToCart();
    history.push('/cash/customer-info');
  }

  const handleOpenPopper = (e) => {
    setOpenPay((prev) => !prev);
    setAnchorEl(e.currentTarget);
  }

  const returnToCart = async () => {
    await makeReturnToCart();
  }

  const cancelOrder = async () => {
    await axios.put(`orders/${orderId}`, { order_status_id: 4 })
      .catch(err => console.log(err.response.data));
    await axios.delete(`/admin/orders/${orderId}`)
      .catch(err => console.log(err.response.data))
    window.location.href = '/cash'
  }

  return (
    <Grid className={classes.total}>
      <CartComment
        defaultValue={commentItem.comment}
        product={commentItem}
        addComment={changeComment}
        openComment={Boolean(commentItem.name)}
        setOpenComment={setCommentItem} />
      <IconButton component={Link} to="/cash/menu" onClick={() => returnToCart()} className={classes.returnButton} color="primary">
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <IconButton onClick={() => cancelOrder()} className={classes.closeButton} color="primary">
        <Close fontSize="large" />
      </IconButton>
      <Typography className={classes.title} variant="h5">Заказ №{checkId}</Typography>
      <Grid className={classes.tableWrap}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Имя</TableCell>
              <TableCell align="right">Цена</TableCell>
              <TableCell align="right">Со скидкой</TableCell>
              <TableCell align="right">Количество</TableCell>
              <TableCell align="right">Итого</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item, index) => {
              const { options, priceBeforeCoupon, name, price, quantity, total } = item;
              let fullName = options.pizza ? `${name} - ${options.pizza.value}смб ${options.pizza.name}, ${options.sauce ? `${options.sauce.value} соус` : 'Без соуса'}` : name;
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}. {fullName}
                  </TableCell>
                  <TableCell align="right">{priceBeforeCoupon ? priceBeforeCoupon : price}</TableCell>
                  <TableCell align="right">{priceBeforeCoupon ? price : '-'}</TableCell>
                  <TableCell align="right">{quantity}</TableCell>
                  <TableCell align="right">{total}</TableCell>
                  <TableCell align="right">
                    {
                      item.comment ? <IconButton onClick={() => setCommentItem(item)} >
                        <Message style={{ color: item.comment.length ? pink[700] : grey[500] }} />
                      </IconButton> : null
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid className={classes.orderInfoFooter}>
        <CouponField />
        <Grid className={classes.orderInfo}>
          <Grid className={classes.totalPrice}>
            <Typography className={classes.totalTypography} variant="h5">Стоимость:</Typography>
            <Typography className={classes.totalTypography} variant="h5">{cart.initialPrice || cart.totalPrice}р.</Typography>
          </Grid>
          <Grid className={classes.totalPrice}>
            <Typography className={classes.totalTypography} variant="h5">Скидка:</Typography>
            <Typography className={classes.totalTypography} variant="h5"> {cart.initialPrice === 0 ? 0 : cart.totalPrice - cart.initialPrice}р.</Typography>
          </Grid>
          <Grid className={classes.totalPrice}>
            <Typography className={classes.totalTypography} variant="h5">Итого:</Typography>
            <Typography className={classes.totalTypography} variant="h5">{cart.totalPrice}р.</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.actionButtons}>
          <IconButton onClick={moveToDeliveryPage} color="primary" aria-label="Новый заказ" className={classes.shippingOrderButton}>
            <LocalShippingIcon fontSize="large" />
          </IconButton>
          <Grid className={classes.payButtons}>
            <Button variant="contained" disabled={orderPending}  className={classes.actionButton} onClick={handleOpenPopper}>{orderPending ? <CircularProgress size={30} className={classes.spinner} /> : null}Без оплаты</Button>
            <Button disabled={orderPending || cart.order.pay_method === 'card' ? true : false} variant="contained" className={classes.actionButton} color="primary" onClick={handleOpenCalculator}>{orderPending ? <CircularProgress size={30} className={classes.spinner} /> : null}Наличные</Button>
            <Button size="large" className={classes.actionButton} variant="contained" disabled={orderPending || cart.order.pay_method === 'cod' ? true : false} onClick={() => cardPayment()} color="primary">{orderPending ? <CircularProgress size={30} className={classes.spinner} /> : null}Карта</Button>
          </Grid>
          <Popper open={openPay} anchorEl={anchorEl} placement={'top'} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Grid className={classes.popperHint}>
                  <Button onClick={() => fakePayment().fakeCardPayment()} style={{ background: 'white' }} disabled={cart.order.pay_method === 'cod' ? true : false} variant="outlined">Карта</Button>
                  <Button onClick={() => fakePayment().fakeCashPayment()} style={{ background: 'white' }} disabled={cart.order.pay_method === 'card' ? true : false} variant="outlined">Наличные</Button>
                </Grid>
              </Fade>
            )}
          </Popper>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(OrderInfo);
