/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { changeComment, firstTimeAdd, submitPayment, toggleSuccessPayment, makeReturnToCart, recountTotalPrice, returnInitialProducts } from '../../redux/actions/cartActions';
import { getCategories } from '../../redux/actions/dataActions';
import SuccessPay from './SuccessPay';
import Calculator from './Calculator';
import PromoPizza from './PromoPizza';
import OrderInfo from './OrderInfo';
import { connect } from 'react-redux';
import axios from 'axios';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({

  componentWrap: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 'calc(100vw - 2rem)',
    height: 'calc(100vh - 2rem)',
    margin: '1rem',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.32)',
    background: 'white'
  },
  ...theme.mainStyles
})
const PayPage = ({ changeComment, firstTimeAdd, returnInitialProducts, submitPayment, recountTotalPrice, classes, cart, toggleSuccessPayment, getCategories, data, data: { categoriesLoaded, orderId }, makeReturnToCart, promo }) => {
  let { order, products, promoAdded, firstTimeAdded } = cart;
  const [orderFinished, setOrderFinished] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [orderPending, setOrderPending] = useState(false);
  const [price, setPrice] = useState('');
  const [paymentOptions, setPaymentOptions] = useState({
    'order_status_id': 2,
    'total': cart.totalPrice,
    'code': promo.code || null
  })
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (promo.type) {
      recountTotalPrice(null, promo.type);
    } else {
      recountTotalPrice();
    }
    setPaymentOptions({
      ...paymentOptions,
      'code': promo.code
    })
    if (firstTimeAdded) {
      if (promoAdded === true || promoAdded === false) {
        //Обновляем order после того, как произошло какое-то действие с купоном
        submitPayment();
      }
    }
  }, [promoAdded])

  useEffect(() => {
    document.title = 'Оплата заказа - Marti\'s CRM'
    if (categoriesLoaded === false) {
      getCategories();
    }
    if (!firstTimeAdded) {
      //отправляем заказ на сервер
      isInitialMount.current = false;
      axios.put(`/orders/add/${orderId}`, {
        order, products
      })
        .then(() => firstTimeAdd())
        .catch(err => console.error(err.response.data));
    }
    return () => {
      isInitialMount.current = true;
    }
  }, []);

  useEffect(() => {
    if (firstTimeAdded) {
      //обновляем заказ после того, как изменяется order
      if (order.total === cart.totalPrice) {
        if (isInitialMount.current && promoAdded !== null) {
          //убираем возможность отработать этому useEffect при первом рендеринге страницы оплаты
          isInitialMount.current = false;
        } else {
          setOrderPending(true);
          axios.put(`/orders/${orderId}`, {
            order, products
          })
            .then(() => {
              setPaymentOptions({
                ...paymentOptions,
                'total': cart.totalPrice,
              });
              setOrderPending(false);
            })
            .catch((err) => {
              setOrderPending(false);
              console.error(err.response.data)
            });
        }
      }
    };
  }, [order])

  const handleOpenCalculator = () => {
    setOpenCalculator(true);
  }

  useEffect(() => {
    if (cart.order.time) {
      setPaymentOptions({
        'order_status_id': 7,
        'total': cart.totalPrice,
        'code': promo.code || ''
      })
    }
  }, [cart.opder])

  useEffect(() => {
    if (orderFinished) {
      const payTheOrder = () => {
        axios.put(`/orders/${orderId}`, paymentOptions)
          .then(() => toggleSuccessPayment(true))
          .catch(err => console.error(err.response.data));
      }
      payTheOrder()
    }
  }, [orderFinished])


  const cashPayment = () => {
    let changeValue = price - cart.totalPrice;
    if (products.length > 0 && changeValue >= 0) {
      setPaymentOptions({
        ...paymentOptions,
        is_paid: 1
      })
    }
    setOrderFinished(true);
  }

  const cardPayment = () => {
    setPaymentOptions({
      ...paymentOptions,
      'pay_method': 'card',
      is_paid: 1
    })
    setOrderFinished(true);
  }

  const fakePayment = () => {
    return {
      fakeCashPayment: () => {
        setPaymentOptions({
          ...paymentOptions,
          is_paid: 0
        })
        setOrderFinished(true);
      },
      fakeCardPayment: () => {
        setPaymentOptions({
          ...paymentOptions,
          'pay_method': 'card',
          is_paid: 0,
        })
        setOrderFinished(true);
      }
    }
  }

  return (
    <Grid className={classes.componentWrap}>
      <PromoPizza />
      <SuccessPay paymentOptions={paymentOptions} />
      <OrderInfo
        orderPending={orderPending}
        handleOpenCalculator={handleOpenCalculator}
        reduxFunctions={{ changeComment, returnInitialProducts, recountTotalPrice, makeReturnToCart }}
        fakePayment={fakePayment}
        rows={cart.products}
        state={{ data, cart, promo }}
        cardPayment={cardPayment} />
      <Calculator
        openCalculator={openCalculator}
        closeCalculator={() => setOpenCalculator(false)}
        cart={cart}
        price={price}
        totalPrice={cart.totalPrice}
        setPrice={setPrice}
        cashPayment={cashPayment} />
    </Grid>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  data: state.data,
  promo: state.promo
})

export default connect(mapStateToProps, { changeComment, firstTimeAdd, returnInitialProducts, submitPayment, toggleSuccessPayment, getCategories, makeReturnToCart, recountTotalPrice })(withStyles(styles)(PayPage));